import * as React from 'react';

import Link from 'gatsby-link';

const CookiePolicyPage = () => {
    // TODO: Fix me! I need to be written
    return (
        <div>
            <div>We are currently not using cookies.</div>
            <Link to="/" className="button">
                Home
            </Link>
        </div>
    );
};

export default CookiePolicyPage;
